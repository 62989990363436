
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonLabel, IonItem, IonList } from '@ionic/vue';


export default  {
    name: 'Recipes',
    components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonLabel, IonItem, IonList },
    data() {
        return {
            tools: [
                {
                    name: "hefekugelrechner",
                    label: "Hefekugel-Rechner"
                },
                {
                    name: "ausrollrechner",
                    label: "Ausrollmaß-Rechner"
                },
                {
                    name: "backzeitrechner",
                    label: "Backzeit-Rechner"
                },
                {
                    name: "backzeitrechner",
                    label: "Umrechnungstabellen"
                },
                {
                    name: "gutebaecker",
                    label: "Gute Bäcker"
                },
                {
                    name: "gutemuehlen",
                    label: "Gute Mühlen"
                },
                {
                    name: "backsupport",
                    label: "Back Support"
                },
                {
                    name: "forum",
                    label: "Forum"
                },
                {
                    name: "datenschutz",
                    label: "Datenschutz"
                }
            ]
        }
    },
    computed: {

    },
}
